import axios from 'axios';

class CookiePolicy {
    constructor() {
        this.cookiesPanel = document.querySelector('.cookies');
        this.cookiesInnerPanel = this.cookiesPanel.querySelector('.cookies__inner');
        this.initialPage = this.cookiesPanel.querySelector('.cookies__content');
        this.manageSettingsBtn = this.cookiesPanel.querySelector('.cookies__manage');
        this.acceptBtn = this.cookiesPanel.querySelector('.cookies__accept');
        this.settingsPanel = this.cookiesPanel.querySelector('.cookies__settings');
        this.settingsPanelCloseBtn = this.settingsPanel.querySelector('.cookies__close_btn');
        this.manageSettingsBtn.addEventListener('click', this.openSettings);
        this.settingsPanelCloseBtn.addEventListener('click', this.closeSettings);
        this.acceptBtn.addEventListener('click', this.acceptCookiePolicy);
        this.advertisingCheckbox = this.settingsPanel.querySelector('.cookies__toggle__checkbox-advertising');
        this.essentialCheckbox = this.settingsPanel.querySelector('.cookies__toggle__checkbox-essential');
        this.analyticsCheckbox = this.settingsPanel.querySelector('.cookies__toggle__checkbox-analytics');
        this.declineAllBtn = this.settingsPanel.querySelector('.cookies__decline');
        this.settingsAcceptBtn = this.settingsPanel.querySelector('.cookies__settings-accept');
        this.advertisingCheckbox.addEventListener('change', this.updateCookiePrefs);
        this.essentialCheckbox.addEventListener('change', this.updateCookiePrefs);
        this.analyticsCheckbox.addEventListener('change', this.updateCookiePrefs);
        this.declineAllBtn.addEventListener('click', this.declineAllCookies);
        this.settingsAcceptBtn.addEventListener('click', this.acceptSettings);

        this.state = {
            advertising: true,
            essential: true,
            analytics: true,
        };
    }

    updateCookiePrefs = (e) => {
        const value = e.target.dataset.cookie;
        this.state[value] = !this.state[value];
    }

    declineAllCookies = () => {
        const toggleSwitches = [
            this.advertisingCheckbox,
            this.essentialCheckbox,
            this.analyticsCheckbox,
        ];

        toggleSwitches.forEach((toggleSwitch) => {
            if (toggleSwitch.checked) {
                toggleSwitch.parentElement.click();
            }
        });

        setTimeout(() => {
            this.setCookie();
            this.closePanel();
        }, 250);
    }
    
    openSettings = () => {
        this.initialPage.classList.remove('cookies__content--open');
        this.cookiesInnerPanel.classList.add('cookies__inner--full');
        this.settingsPanel.classList.add('cookies__settings--open');
    };

    closeSettings = () => {
        this.initialPage.classList.add('cookies__content--open');
        this.cookiesInnerPanel.classList.remove('cookies__inner--full');
        this.settingsPanel.classList.remove('cookies__settings--open');
    }

    closePanel = () => {
        this.cookiesPanel.remove();
    }

    acceptSettings = () => {
        this.setCookie();
        this.closePanel();
    }

    setCookie = () => {
        let todayPlus2Years = new Date(new Date().setFullYear(new Date().getFullYear() + 2));
        const cookieString = `rscookiepolicy=advertising=${this.state.advertising}|essential=${this.state.essential}|analytics=${this.state.analytics};expires=${todayPlus2Years.toUTCString()}`;
        document.cookie = cookieString;
    }

    acceptCookiePolicy = () => {
        this.setCookie();
        this.closePanel();
    }
}

const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

document.addEventListener('DOMContentLoaded', async () => {
    const res = await axios.get('/service/v1/cookie-policy');
    const data = res.data;

    if (!getCookie('rscookiepolicy')) {
        document.body.insertAdjacentHTML('beforeend', data);
        new CookiePolicy();
    }   
});